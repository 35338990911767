import React from "react";
import { Link } from "gatsby";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSmile,
  faFrownOpen,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { getApiConfig } from "../../apiConfig";

import imgLB from "../../assets/image/logo-main-black.png";
import imgLW from "../../assets/image/logo-main-white.png";
import imgFS from "../../assets/image/inner-page/png/yellow-footer-shape.png";

library.add(faSmile, faFrownOpen, faSpinner);
const apiConfig = getApiConfig();

const Footer = ({ className, ...rest }) => {
  const [mailingAddress, setMailingAddress] = useState(null);
  const [addedToListSuccess, setAddedToListSuccess] = useState(null);
  const [addedToListFail, setAddedToListFail] = useState(null);
  const [handlingForm, setHandlingForm] = useState(false);

  let api_url = apiConfig.apiUrl;
  let api_headers = {
    "x-api-key": apiConfig.apiKey,
    "Content-Type": "application/json",
  };

  const handleMailingForm = (event) => {
    event.preventDefault();
    setHandlingForm(true);
    fetch(api_url + "/subscriber/add", {
      method: "PUT",
      headers: api_headers,
      body: JSON.stringify({ email: mailingAddress }),
    }).then((response) => {
      setHandlingForm(false);
      console.log(response.status);
      setMailingAddress(null);
      document.getElementById("mailing-list-form").reset();
      setAddedToListSuccess(response.status === 202);
      setAddedToListFail(response.status !== 202);
    });
  };

  return (
    <>
      <div
        className={`bg-dark-cloud pt-13 pt-lg-27 pb-7 dark-mode-texts position-relative ${className}`}
        {...rest}
      >
        <div className="container">
          <div className="row pb-lg-25">
            <div className="col-lg-4 col-md-5 col-md-3 col-xs-8">
              <div className="pr-xl-20 mb-11 mb-lg-0 mt-lg-5">
                <div className="brand-logo mb-5">
                  CLEANERBILITY&trade;
                  {/* <Link to="/#">
                    <img
                      className="mx-auto mx-0 light-version-logo default-logo"
                      src={imgLB}
                      alt=""
                    />
                    <img
                      src={imgLW}
                      alt=""
                      className="dark-version-logo mx-auto mx-0"
                    />
                  </Link> */}
                </div>
                <p className="font-size-5 mb-0 text-bali-gray pr-sm-10 pr-md-0">
                  The management application for smaller cleaning companies to
                  manage employees and their tasks.
                </p>
                <div id="mailing-list-container" className="mt-8">
                  <form
                    id="mailing-list-form"
                    onSubmit={handleMailingForm}
                    className="form-inline"
                  >
                    <div>
                      <p>Join mailing list</p>
                      <div className="input-group">
                        <input
                          onChange={(e) => setMailingAddress(e.target.value)}
                          required
                          className="form-control"
                          type="email"
                          placeholder="your.email@addre.ss"
                          name="mailing-email-address"
                        />
                      </div>
                      <div className="input-group">
                        <button
                          disabled={handlingForm}
                          className="btn btn-primary"
                          type="submit"
                        >
                          {handlingForm && (
                            <FontAwesomeIcon size="2x" spin icon="spinner" />
                          )}
                          {!handlingForm && "Join"}
                        </button>
                      </div>
                    </div>
                  </form>
                  <div id="mailing-list-form-report">
                    {addedToListSuccess && (
                      <div className="report-success">
                        <FontAwesomeIcon icon="smile" /> You've been added
                      </div>
                    )}
                    {addedToListFail && (
                      <div className="report-fail">
                        <FontAwesomeIcon icon="frown-open" /> We are unable to
                        add you.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 offset-xl-1">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-md-3 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      Company
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <Link
                          to="/security"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Security
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/terms"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          T & C
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/privacy"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/sustainability"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Sustainability
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-md-3 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      Support
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <Link
                          to="/training"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Training
                        </Link>
                      </li>
                      <li className="mb-6">
                        <a
                          href="https://docs.cleanerbility.com"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Help docs
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-lg-6 col-md-5 col-xs-8">
                  <div className="mb-10 mb-lg-0 mr-xl-12">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      Contacts
                    </h4>
                    <p className="font-size-5 mb-0 text-lily-white">
                      Feel free to get in touch with us via phone or send us a
                      message.
                    </p>
                    <div className="mt-7">
                      <a
                        href="tel://+442921283889"
                        className="font-size-5 d-block text-golden-yellow mb-0"
                      >
                        +44 2921 28 3889
                      </a>
                      <p className="font-size-5 d-block text-golden-yellow mb-0">
                        support@cleanerbility.com
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-10 border-top">
            <div className="col-lg-6 text-center text-lg-left">
              <div className="copyright">
                <p className="mb-0 font-size-3 text-bali-gray">
                  ©2023 Cleanerbility Ltd, All Rights Reserved
                  <br />
                  Cleanerbility Ltd is a company registered in England & Wales -
                  reg no. 13997968.
                  <br />
                  Registered Address: Suite 10, Platfform, 11 Devon Place,
                  Newport, NP20 4NW{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <div className="footer-right mt-5 mt-lg-0">
                <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end mb-0">
                  <li>
                    <a
                      aria-label="Follow Cleanerbility on Twitter"
                      title="Follow Cleanerbility on Twitter"
                      href="https://www.twitter.com/cleanerbility"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="Find us on Facebook"
                      title="find us on Facebook"
                      href="https://www.facebook.com/cleanerbility"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="Cleanerbility Google Profile"
                      title="Cleanerbility Google Profile"
                      href="https://www.google.com/search?q=Cleanerbility+Ltd"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-google" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-yellow-shape-img">
          <img src={imgFS} alt="" />
        </div>
      </div>
    </>
  );
};

export default Footer;
